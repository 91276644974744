import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import ConfirmAction from '../context/ConfirmActionContext'
import InputField from './InputField'

import Popup from './Popup'
import Button from './Button'

const Container = styled.div`
    padding: 1rem 2rem;
`

const Title = styled.div`
    font-weight: bold;
    color: ${p => p.theme.dark};
`

const Line = styled.div`
    height: .05rem;
    margin: 1rem 0;
    background-color: ${p => p.theme.gray};
`

const Row = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-auto-flow: row;
    }
`

export default function ConfirmActionPopup() {
    const state = useContext(ConfirmAction)

    const confirmHandler = () => {
        if (state.onConfirm) state.onConfirm()
        if (state.onTextConfirm) state.onTextConfirm(state.text)
        state.hide()
    }

    return (
        <> {
        state.isActive && (<Popup onClose={state.hide}>
            <Container>
                <Title>{state.title}</Title>
                <Line />
                {
                    state.onTextConfirm &&
                    <InputField 
                        value={state.text}
                        onValueChange={state.setText}
                        placeholder={state.placeholder}
                        height='2rem' 
                        style={{marginBottom: '1rem', width: '100%'}}/>
                }
                <Row>
                    <Button onClick={state.hide}>{state.cancelText ? state.cancelText : 'Cancel'}</Button>
                    <Button primary onClick={confirmHandler}>{state.confirmText ? state.confirmText : 'Confirm'}</Button>
                </Row>
            </Container>
        </Popup>)
        } </>
    )
}
