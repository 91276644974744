import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    background-image: url(${p => p.url});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`

export default function QR(props) {
    return (
        <Container 
        style={props.style}
        url={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURI(props.link)}`}
        />
    )
}