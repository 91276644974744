import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Route, Switch, Link, useHistory, Redirect } from 'react-router-dom'

import { useContent } from '../hooks/CustomHooks'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import TextButton from '../components/TextButton'
import ConfirmActionPopup from '../components/ConfirmActionPopup'
import Notifications from '../components/Notifications'

import Experience from './home/Experience'
import Enviroment from './home/Enviroment'
import Interactive from './home/Interactive'
import Map from './home/Map'
import About from './home/About'
import GetTheApp from './home/GetTheApp'

import Profile from './Profile'
import Account from './Account'
import Embed from './Embed'

import ContentPlayer from './ContentPlayer'
import ImageDisplay from './ImageDisplay'
import InteractivePlayer from './InteractivePlayer'

import Popup from './../components/Popup'
import Login from './Login'
import Register from './Register'

import { getMobileOperatingSystem } from '../utility'
import ConfirmAction from '../context/ConfirmActionContext'

import { brand } from '../Exports' 

const Container = styled.div`
`

const Body = styled.div`
    min-height: calc(100vh - 17rem - 10em);
    margin: 5em auto;
    max-width: 65em;

    @media only screen and (max-width: ${p => p.theme.tablet}) {
        padding: 0 2rem;
    }

    @media only screen and (max-width: ${p => p.theme.mobile}) {
        max-width: 95vw;
    }
`

export default function Home() {
    const [state, reducer] = useState({
        loginPanel: false,
        setLoginPanel: s => {state.loginPanel = s; state.registerPanel = false; state.save(); },
        registerPanel: false,
        setRegisterPanel: v => {state.registerPanel = v; state.loginPanel = false; state.save(); },
        save: () => reducer({...state})
    })
    const [getTheAppMessage, setGetTheAppMessage] = useState(true)
    const confirmAction = useContext(ConfirmAction)

    const history = useHistory()

    const [interactivesContent, interactiveLoading] = useContent('experiences')

    const [tabs, setTabs] = useState([])

    if (interactiveLoading === 'done' && tabs.length === 0) {
        if (!interactivesContent) setTabs([
            { name: 'Experiences', content: <Experience/> },
            { name: 'Environments', content: <Enviroment/> },
            { name: 'Map', content: <Map/> },
            { name: 'About', content: <About/> },
            { name: 'Get The App', content: <GetTheApp/> },
        ]) 
        else setTabs([
            { name: 'Experiences', content: <Experience/> },
            { name: 'Environments', content: <Enviroment/> },
            { name: 'Activities', content: <Interactive/> },
            { name: 'Map', content: <Map/> },
            { name: 'About', content: <About/> },
            { name: 'Get The App', content: <GetTheApp/> },
        ])
    }

    const getUrlPath = name => name === 'Activities' ? 'interactives' : name.toLowerCase().replace(/\s/g, '')

    const isSelected = i => history.location.pathname.includes(getUrlPath(tabs[i].name))

    const getNavButtons = () => tabs.map((t, i) => 
    <Link key={i} to={`/${getUrlPath(t.name)}`}>
        <TextButton selected={isSelected(i)}> {t.name} </TextButton></Link>)
    
    const getTabs = () => tabs.map((t, i) =>
        <Route key={i} path={`/${getUrlPath(t.name)}`}>{t.content}</Route>
    )

    const operatingSystem = getMobileOperatingSystem()
    if (operatingSystem === 'Android' ||
        operatingSystem === 'iOS') {
            if (getTheAppMessage) {
                setGetTheAppMessage(false)
                confirmAction.ask({
                    title: `For an improved mobile experience, please download the ${brand.title} App!`,
                    confirmText: 'Download',
                    cancelText: 'No thanks',
                    onConfirm: () => {
                        // Go to app store.
                        window.open(operatingSystem === 'iOS' ? brand.appStoreLink : brand.googlePlayLink)
                    }
                })       
            }
        }

    return (
        <>
        {history.location.pathname === '/' && <Redirect to='/experiences' />}
        <Navbar 
            signInHandler={() => state.setLoginPanel(true)}
            signUpHandler={() => state.setRegisterPanel(true)}
        >{getNavButtons()}</Navbar> 
        <Container>
            <Body>
                <Switch>
                    {getTabs()}
                    <Route path='/player'><ContentPlayer /></Route>
                    <Route path='/display'><ImageDisplay /></Route>
                    <Route path='/profile'><Profile /></Route>
                    <Route path='/account'><Account /></Route>
                    <Route path='/activityplayer'><InteractivePlayer /></Route>
                    <Route path='/embed'><Embed /></Route>
                </Switch>
            </Body>
            {state.loginPanel && (<Popup onClose={() => state.setLoginPanel(false)}>
                <Login 
                    signUpHandler={() => state.setRegisterPanel(true)}
                    loginHandler={() => state.setLoginPanel(false)} />
                </Popup>)}
            {state.registerPanel && (<Popup onClose={() => state.setRegisterPanel(false)}>
                <Register 
                    signInHandler={() => state.setLoginPanel(true)}
                    signUpHandler={() => state.setRegisterPanel(false)}
                />
            </Popup>)}
            <ConfirmActionPopup />
            <Notifications />
        <Footer>{getNavButtons()}</Footer>
        </Container>
        </>
    )
}