import React, { useState } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react';

export default function MapContainer(props) {
    const [center, setCenter] = useState({lat: -25.820938, lng: 133.324147});
    const [zoom, setZoom] = useState(4.5);
      
    return (
        <GoogleMapReact
          initialCenter={{ lat: -25.820938, lng: 133.324147}}
          bootstrapURLKeys={{ key: 'AIzaSyBv7A63xiHVHiuEdOHHfWMUTxAh1LxDxi4' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
        {props.children}
        </GoogleMapReact>
    );
}

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyBv7A63xiHVHiuEdOHHfWMUTxAh1LxDxi4'
// })(MapContainer);
