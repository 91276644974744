import React from 'react'
import styled from 'styled-components'
import GoogleMap from './../../googleMap/GoogleMap'
import Reveal from 'react-reveal/Reveal'
import { Link } from 'react-router-dom'

import markerIcon from './../../graphics/icons/pin.svg'
import ContentThumbnail from './../../components/ContentThumbnail'

import { useContent } from '../../hooks/CustomHooks' 

const Container = styled.div`
    position: absolute;
    top: 5em;
    bottom: 0;
    left: 0;
    right: 0;
`

const ContentHolder = styled.div`
    display: none;
    position: relative;
    top: -1.5rem;
    left: 1.5rem;
    width: 20em;
    background-color: ${p => p.theme.light};
    padding: .3em;
    border-radius: ${p => p.theme.roundCorners};
    z-index: 1;
`

const Icon = styled.div`
    position: relative;
    top: -2rem;
    -webkit-mask-image: url(${markerIcon});
    mask-image: url(${markerIcon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${p => p.image ? p.theme.alternative : p.theme.primary};
    margin-left: -1rem;
    width: 2rem;
    height: 2rem;
`

const Marker = styled.div`
    cursor: pointer;
    :hover {
        ${Icon} {
            background-color: ${p => p.theme.primaryHover};
        }
        ${ContentHolder} {
            display: block;
        }
    }
`

export default function Map(props) {
    const [videosContent, videosLoading] = useContent('videos360')
    const [imagesContent, imagesLoading] = useContent('images360')

    const getVideoMarkers = () => {
        if (videosLoading === 'done') {
            return Object.values(videosContent).map(v => 
                <Marker lat={v.latitude} lng={v.longitude} key={v.id}>
                    <Link to={`player?video=${v.id}`}><Icon/></Link>
                    <ContentHolder>
                        <Reveal effect='fadeInUp' duration={500}>
                        <ContentThumbnail 
                            id={v.id}
                            title={v.title}
                            description={v.description}
                            thumbnailImage={v.thumbnail}
                            author='tassal'
                            authorImage='https://firebasestorage.googleapis.com/v0/b/farmvr-74d6a.appspot.com/o/flamelink%2Fmedia%2Fsized%2F667_9999_100%2F1553599888416_tassal-logo.png?alt=media'/>
                        </Reveal>
                    </ContentHolder>
                </Marker>)
        }
    }

    const getImageMarkers = () => {
        if (imagesLoading === 'done') {
            return Object.values(imagesContent).map(i => 
                <Marker lat={i.latitude} lng={i.longitude} key={i.id}>
                    <Link to={`display?image=${i.id}`}><Icon image/></Link>
                    <ContentHolder>
                        <Reveal effect='fadeInUp' duration={500}>
                        <ContentThumbnail 
                            id={i.id}
                            title={i.title}
                            description={i.description}
                            thumbnailImage={i.thumbnail}
                            author='tassal'
                            authorImage='https://firebasestorage.googleapis.com/v0/b/farmvr-74d6a.appspot.com/o/flamelink%2Fmedia%2Fsized%2F667_9999_100%2F1553599888416_tassal-logo.png?alt=media'/>
                        </Reveal>
                    </ContentHolder>
                </Marker>)
        }
    }

    return (
        <Container>
            <GoogleMap>
                {getVideoMarkers()}
                {getImageMarkers()}
            </GoogleMap>
        </Container>
    )
}