import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';

import { sendResetPasswordEmail } from '../firebase/Auth'

import InputField from './../components/InputField'
import Button from './../components/Button'
import LoadingDot from '../components/LoadingDot'

import { useIsLogged } from './../hooks/CustomHooks'
import { setValue } from '../firebase/Database'

import ConfirmAction from '../context/ConfirmActionContext'
import Notifications from '../context/NotificationsContext'

const Container = styled.div`
    padding: 1rem 0;
`

const Title = styled.div`
    margin: 1rem 0;
    color: ${p => p.theme.dark};
    font-weight: bold;
    font-size: 1.5rem;
`

const SubTitle = styled.div`
    margin: .5rem 0;
    color: ${p => p.theme.medium};
    font-size: 1rem;
`

const ProfilePhoto = styled.div`
    margin: 1rem 0;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    background-image: url(${p => p.image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${p => p.theme.medium};
`

const ButtonContainer = styled.div`
    margin: 1rem 0;
    width: 15rem;
`

const Line = styled.div`
    background-color: ${p => p.theme.darkerLight};
    width: 100%;
    height: .05rem;
    margin: 2rem 0;
`

export default function Profile() {
    const userState = useIsLogged()
    const confirmAction = useContext(ConfirmAction)
    const notifications = useContext(Notifications)

    const [state, setState] = useState({
        userName: '',
        setUserName: v => {state.userName = v; state.save(); },
        firstName: '',
        setFirstName: v => {state.firstName = v; state.save(); },
        lastName: '',
        setLastName: v => {state.lastName = v; state.save(); },
        isUpdateing: false,
        setIsUpdateing: v => { state.isUpdateing = v; state.save(); },
        save: () => setState({...state})
    })

    if (!state.userName && !userState.isLoading && userState.isUserLogged) {
        state.setUserName(userState.loggedUser.userName ? userState.loggedUser.userName : userState.loggedUser.email)
        state.setFirstName(userState.loggedUser.firstName)
        state.setLastName(userState.loggedUser.lastName)
    }

    const updateHandler = () => {
        if (!userState.isUserLogged) return

        confirmAction.ask({
            title: 'Do you want to update your profile info?',
            onConfirm: () => {
                state.setIsUpdateing(true)
                setValue('users', userState.loggedUser.localId, {
                    firstName: state.firstName,
                    lastName: state.lastName,
                    userName: state.userName
                })
                .then(m => { state.setIsUpdateing(false); notifications.notify('Info updated successfully') })
                .catch(e => { state.setIsUpdateing(false); console.log(e); })
            }
        })
    }

    const resetPasswordHandler = () => {
        if (!userState.isUserLogged) return

        confirmAction.ask({
            title: `A reset password email will be send to ${userState.loggedUser.email}`,
            onConfirm: () => {
                sendResetPasswordEmail(userState.loggedUser.email).then(() => {
                    notifications.notify(`email sent to ${userState.loggedUser.email}`)
                }).catch(console.log)
            }
        })
    }

    return (
        <Reveal effect='fadeInUp' duration={500}>
        <Container>
            <Title>Basic</Title>
            <SubTitle>Profile Photo</SubTitle>
            <SubTitle>Username</SubTitle>
            <InputField onValueChange={state.setUserName} value={state.userName} width='100%' height='2rem'/>
            <SubTitle>First Name</SubTitle>
            <InputField onValueChange={state.setFirstName} value={state.firstName} width='100%' height='2rem'/>
            <SubTitle>Last Name</SubTitle>
            <InputField onValueChange={state.setLastName} value={state.lastName} width='100%' height='2rem'/>
            <ButtonContainer>
                {state.isUpdateing ? <LoadingDot paddingTop='1rem'/> : <Button onClick={updateHandler} primary>Update</Button>}
            </ButtonContainer>
            <Line />
            <Title>Security</Title>
            <ButtonContainer><Button onClick={resetPasswordHandler} primary>Reset Password</Button></ButtonContainer>
            <SubTitle>Email</SubTitle>
            <InputField width='100%' height='2rem' value={userState.loggedUser && userState.loggedUser.email}/>
            {/* <ButtonContainer><Button primary>Update Email</Button></ButtonContainer> */}
        </Container>
        </Reveal>
    )
}