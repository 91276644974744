import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Shake } from 'reshake'

import Logo from '../components/Logo'
import Button from '../components/Button'
import InputField from '../components/InputField'
import LoadingDot from '../components/LoadingDot'

import Notifications from '../context/NotificationsContext'

import { register } from './../firebase/Auth'

const Container = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1em;
    width: 15rem;
`

const Title = styled.div`
    color: ${p => p.theme.medium};
    text-align: center;
`

const LogoContainer = styled.div`
    height: 4em;
`

const Description = styled.div`
    color: ${p => p.theme.medium};
    text-align: center;
    font-size: .8rem;
`

const Line = styled.div`
    height: .05rem;
    background-color: ${p => p.theme.gray};
`

export default function Register(props) {
    const notifications = useContext(Notifications)
    const [state, setState] = useState({
        email: '',
        setEmail: v => { state.email = v; state.save(); },
        password: '',
        setPassword: v => { state.password = v; state.save(); },
        confirmPassword: '',
        setConfirmPassword: v => { state.confirmPassword = v; state.save(); },
        isShakeing: false,
        setIsShakeing: v => { state.isShakeing = v; state.save(); },
        shake: () => { state.setIsShakeing(true); setTimeout(() => { state.setIsShakeing(false) }, 200); },
        isLoading: false,
        setIsLoading: v => { state.isLoading = v; state.save(); },
        save: () => setState({...state})
    })

    const signUpHandler = () => {
        if (state.email === '' || state.password === '') {
            notifications.notify(`Can't be empty fields`)
            state.shake()
            return
        }
        if (state.password != state.confirmPassword) {
            notifications.notify(`Password Confirmation doesn't match Password`)
            state.shake()
            return
        }

        state.setIsLoading(true)
        register({
            email: state.email,
            password: state.password,
        }, response => {
            state.setIsLoading(false)
            if (response.status === 1) { // Error
                notifications.notify(response.error.message)
                state.shake()
                return
            }

            props.signUpHandler()
        })
    }

    const container = (
        <Container>
            <Title>Welcome to</Title>
            <LogoContainer><Logo /></LogoContainer>
            <Button onClick={props.signInHandler}>Sign In</Button>
            <Description>Do you already have an account?</Description>
            <Line />
            <Title>Create Account</Title>
            <InputField 
                value={state.email}
                onValueChange={state.setEmail}
                height='2rem'
                placeholder='Email'
                type='email'
            />
            <InputField 
                value={state.password}
                onValueChange={state.setPassword}
                height='2rem'
                placeholder='Password'
                type='password'
            />
            <InputField 
                value={state.confirmPassword}
                onValueChange={state.setConfirmPassword}
                height='2rem'
                placeholder='Confirm Password'
                type='password'
            />
            {state.isLoading ? <LoadingDot paddingTop='0'/> :
            <Button onClick={signUpHandler} primary>Sign Up</Button> }
        </Container>
    )

    return (
        <>
        {state.isShakeing ? <Shake fixed={true} h={9} dur={300} int={1} max={100}>{container}</Shake> : container }
        </>
    )
}