import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'

import { brand } from '../Exports'

import { useIsLogged } from './../hooks/CustomHooks'
import Search from '../context/SearchContext'

import Button from './Button'
import InputField from './InputField'

import Logo from './Logo'
import menuIcon from './../graphics/icons/menu-button.svg'
import profileIcon from './../graphics/icons/user.svg'

const Container = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 5em;
    border-bottom: .13em solid ${p => p.theme.darkerLight};
    ${p => !p.rest && css`
        background-color: ${p => p.theme.light};
    `}

    z-index: 1;
`

const Layout = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 9rem auto 30rem;
    @media only screen and (max-width: ${p => p.theme.tablet}) {
        grid-template-columns: 8rem auto 5rem;
    }
    margin: 0 1em;
    grid-gap: 2em;
    padding: 1em;
`

const Row = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 3em;
    align-items: center;
    margin-right: auto;
    @media only screen and (max-width: ${p => p.theme.tablet}) {
        display: none;
    }
`

const RigthPart = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    padding: .4em;
    padding-right: 2em;
    grid-column: 3 / 4;
    @media only screen and (max-width: ${p => p.theme.tablet}) {
        display: none;
    }
`

const LogoHolder = styled.div`
    cursor: pointer;
    width: 100%;
    height: 100%;
`

const MenuButton = styled.div`
    background-color: ${p => p.light ? p.theme.light : p.theme.medium};
    margin: auto;
    :hover { 
        background-color: ${p => p.theme.primary};
    }
    cursor: pointer;
    -webkit-mask-image: url(${menuIcon});
    mask-image: url(${menuIcon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    grid-column: 3 / 4;
    @media only screen and (min-width: ${p => p.theme.tablet}) {
        display: none;
    }

    ${p => p.light && css`
        grid-column: 1/2;
    `}
`

const MenuMobile = styled.div`
    position: fixed;
    top: 0; 
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${p => p.theme.medium};
    padding: 1rem;
    @media only screen and (min-width: ${p => p.theme.tablet}) {
        display: none;
    }

    display: grid;
    grid-template-rows: auto auto;
`

const FlowGrid = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
    margin-top: 2rem;

    & > * {
        font-size: 1.5rem;
        text-align: center;
    }
`

const Line = styled.div`
    background-color: ${p => p.theme.light};
    height: .08rem;
`

const Title = styled.div`
    color: ${p => p.theme.light};
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
`

function ProfileButton(props) {
    const history = useHistory()
    const userState = useIsLogged()

    const clickHandler = () => {
        if (userState.isUserLogged) history.push('/account')
        else props.signInHandler()
    }

    const getName = () => {
        return userState.loggedUser.firstName ? userState.loggedUser.firstName : 'Profile'
    }

    return userState.isUserLogged ? <Button primary onClick={clickHandler}>{getName()}</Button> :
        <>
        <Button primary onClick={clickHandler}>SIGN IN</Button>
        <Button onClick={props.signUpHandler}>SIGN UP</Button>
        </>
}

function SearchBar(props) {
    const history = useHistory()
    const [searchState, searchReducer] = useContext(Search)

    const searchBar = <InputField 
        style={{width:'10rem'}}
        onValueChange={v => searchReducer(v)} 
        value={searchState}
        placeholder='Search'>
        </InputField>

    const path = history.location.pathname
    if (path.includes('experiences') || path.includes('environments')) return searchBar
    return <div style={{width:'5rem'}}/>
}

export default function Navbar(props) {
    const [state, reducer] = useState({
        rest: true,
        setRest: s => { state.rest = s; state.save(); },
        save: () => reducer({...state})
    })
    const [menuState, setMenuState] = useState(false)

    const menuClickHandler = () => {
        setMenuState(!menuState)
    }

    useEffect(() => {
        window.onscroll = () => {
            if (window.scrollY > 0 && state.rest) state.setRest(false)
            if (window.scrollY === 0 && !state.rest) state.setRest(true)
        }
    })  

    return (
        <Container rest={state.rest}>
            <Layout>
                <Link to='/'><LogoHolder><Logo /></LogoHolder></Link>
                <Row>{props.children}</Row>
                <RigthPart>
                    <SearchBar />
                    <ProfileButton 
                        signInHandler={props.signInHandler}
                        signUpHandler={props.signUpHandler}/>
                </RigthPart>
                <MenuButton onClick={menuClickHandler} />
            </Layout>
            {menuState && 
                <MenuMobile onClick={menuClickHandler}>
                    <Link to='/'><LogoHolder><Logo logo={2}/></LogoHolder></Link>
                    <FlowGrid>
                        {props.children}
                        <Line />
                        <ProfileButton 
                            height='3rem'
                            signInHandler={props.signInHandler}
                            signUpHandler={props.signUpHandler}/>
                    </FlowGrid>
                </MenuMobile>}
        </Container>
    )
}
