import React from 'react'
import styled, { css } from 'styled-components'

import { brand } from '../Exports'

const Container = styled.div`
    background-image: url(${p => p.logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;

    ${p => p.left && css`
        background-position: left;
    `}

    ${p => p.height && css`
        height: ${p => p.height};
    `}
`

export default function Logo(props) {
    const getLogo = () => {
        if (props.logo == 1) return brand.logoB
        if (props.logo == 2) return brand.logoC
        return brand.logoA
    }
    return (
        <Container left={props.left} height={props.height} logo={getLogo()} />
    )
}