import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import VideoPlayer from './../components/VideoPlayer'

import { getUrl, getElement } from './../firebase/Database'
import { useContent, useParam } from './../hooks/CustomHooks'
import { brand } from '../Exports'

const Info = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: white;
    border-radius: .4rem;
    padding: .5rem 1rem;

    display: grid;
    grid-template-columns: 3rem auto;
    grid-gap: 1rem;
`
const Title = styled.div`
    color: ${p => p.theme.dark};
    font-size: 1rem;
    font-weight: bold;
`

const Author = styled.div`
    background-image: url(${p => p.image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0; 
    right: 0;
    z-index: 50;
    background-color: black;
`

const Thumbnail = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(${p => p.image});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
    pointer-events: none;
`

const PlayButton = styled.div`
    position: absolute;
    border: 3px solid white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    border-radius: 10px;
    width: 90px;
    height: 45px;

    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default function Embed(props) {
    const videoId = useParam('video')
    const [videos, videosLoading] = useContent('videos360')
    const [videoUrl, setVideoUrl] = useState()
    const [thumbnail, setThumbnail] = useState()
    const [preview, setPreview] = useState(true)
    const [title, setTitle] = useState()
    const [authorUrl, setAuthorUrl] = useState('')

    useEffect(() => {
        if (videosLoading != 'done' || videoUrl || !videoId) return
        const video = videos[videoId]
        const path = video.sdVideo ? Object.values(video.sdVideo)[0] : Object.values(video.hdVideo)[0]

        getUrl(path).then(setVideoUrl)
        getUrl(Object.values(video.thumbnail)[0]).then(setThumbnail)
        setTitle(video.title)
        let authorId = video.author ? video.author : brand.defaultAuthor
        getElement('authors', authorId).then(a => {
        if (!a) return;
        getUrl(Object.values(a.logo)[0]).then(setAuthorUrl)
    })

    }, [videosLoading])

    const playHandler = () => {
        setPreview(false)
    }

    return (
        <Container> 
            { preview && <Preview title={title} thumbnail={thumbnail} authorUrl={authorUrl}/> }
            { videoUrl && <Video playHandler={playHandler} videoUrl={videoUrl}/> }
        </Container>
    )
}

function Video(props) {
    return <VideoPlayer onPlay={props.playHandler} onPause={props.pauseHandler} custom video={props.videoUrl}/>
}

function Preview(props) {
    return <Thumbnail show={props.show} image={props.thumbnail}>
        <Info>
            <Author image={props.authorUrl}/>
            <Title>{props.title}</Title>
        </Info>
        <PlayButton> ▶ </PlayButton>
    </Thumbnail>
}