import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
`

const Background = styled.div`
    background-color: rgb(255, 255, 255, .8);
    :hover {
        background-color: rgb(255, 255, 255, .7);
    }
    grid-row: 1/4;
    grid-column: 1/4;
    cursor: pointer;
`

const Panel = styled.div`
    background-color: ${p => p.theme.light};
    box-shadow: 0 .3em .4em rgb(0,0,0,.2);
    border-radius: ${p => p.theme.rounderCorners};
    padding: 1em;
`

const Holder = styled.div`
    grid-row: 2/3;
    grid-column: 2/3;
    width: min-content;
    @media only screen and (max-width: ${p => p.theme.mobile}) {
        width: 20rem;
    }
    margin: auto;
`

export default function Popup(props) {
    return (
        <Container>
        <Background onClick={props.onClose}></Background>
        <Holder>
        <Reveal effect='fadeInUp' duration={500}>
        <Panel>
            {props.children}
        </Panel>
        </Reveal>
        </Holder>
        </Container>
    )
}