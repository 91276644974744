import React from 'react'
import styled, { css } from 'styled-components'
import Reveal from 'react-reveal/Reveal';

import TextButton from './TextButton'

import arrowIcon from './../graphics/icons/arrow.svg'

const Container = styled.div`
    position: relative;
    padding: 1em 0;
    border-bottom: .13em solid ${p => p.theme.darkerLight};
    max-width: 65rem;
    @media only screen and (max-width: ${p => p.theme.mobile}) {
        max-width: 80vw;
    }
`

const Layout = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-template-columns: 1fr;
    }

    grid-gap: 2em 1.5em;
    width: 100%;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    ${p => p.preview && css`
        scroll-behavior: smooth;
        grid-auto-flow: column;
        overflow: scroll;

        & > * {
            width: 20.5rem;
        }
    `}
`

const Title = styled.div`
    color: ${p => p.theme.medium};
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: 1em;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    width: min-content;
    white-space: nowrap;
    align-items: baseline;
`

const ButtonHolder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
`

const Arrow = styled.div`
    & > * {
        margin: auto;
        width: 1rem;
        height: 1rem;
        background-color: ${p => p.theme.primary};
        -webkit-mask-image: url(${arrowIcon});
        mask-image: url(${arrowIcon});
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;

        ${p => !p.right && css`
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        `}
    }

    display: flex;
    align-items: center;

    pointer-events: all;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${p => p.theme.light};
    box-shadow: ${p => p.theme.shadow};
    font-weight: bold;
    font-size: 2rem;
    position: absolute;
    margin-top: -1rem;
    top: 50%;
    ${p => p.right && css`
        right: 0;
        margin-right: -1.6rem;
    `}
    ${p => !p.right && css`
        left: 0;
        margin-left: -1.6rem;
    `}
    cursor: pointer;
    :hover {
        background-color: ${p => p.theme.primary};
        & > * {
            background-color: ${p => p.theme.light};
        }
    }
`

const ViewMore = styled.div`
    width: 20rem;
    border-radius: ${p => p.theme.roundCorners};
    background-color: ${p => p.theme.darkerLight};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    cursor: pointer;
    color: ${p => p.theme.dark};
    font-weight: bold;
    font-size: 1.2rem;
    :hover {
        color: ${p => p.theme.primary};
        border: .1em solid ${p => p.theme.primary};
    }
`

export default function Gallery(props) {
    let scroll = React.createRef()

    const scrollClickHandler = right => {
        scroll.current.scrollLeft += right ? 800 : -800; 
    }

    return (
        <Reveal effect='fadeInUp' duration={500}>
            <Container>
                <Title>{props.title}{
                    props.preview && 
                    props.onViewMore && 
                    <TextButton style={{width:'min-content'}} onClick={props.onViewMore}>View More</TextButton>}</Title>
                <Layout ref={scroll} preview={props.preview}>
                    {props.children}
                    { props.preview && props.onViewMore &&
                    <ViewMore onClick={props.onViewMore}>View More</ViewMore>}
                </Layout>
                {props.preview && 
                    <ButtonHolder>
                        <Arrow right onClick={() => scrollClickHandler(true)}><div/></Arrow>
                        <Arrow onClick={() => scrollClickHandler(false)}><div/></Arrow>
                    </ButtonHolder>}
            </Container>
        </Reveal>
    )
}