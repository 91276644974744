import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Search from '../../context/SearchContext'

import Gallery from '../../components/Gallery'
import ContentThumbnail from './../../components/ContentThumbnail'
import CategoriesGallery from '../../components/CategoriesGallery'
import LoadingDot from '../../components/LoadingDot'

import { useContent } from './../../hooks/CustomHooks'

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
`

export default function Enviroment() {
    const history = useHistory()
    const [searchState] = useContext(Search)
    const [categoriesContent, categoriesLoading] = useContent('categories')
    const [imagesContent, imagesLoading] = useContent('images360')

    const onViewMore = category => {
        history.push({search: `?category=${category}`})
        window.scrollTo(0, 0);
    }

    const getCategory = () => {
        const getContentThumbnail = i => 
            <ContentThumbnail
            key={i.id}
            link={`display?image=${i.id}`}
            id={i.id}
            title={i.title}
            description={i.description}
            thumbnailImage={i.thumbnail}
            author={i.author}></ContentThumbnail>

        if (categoriesLoading === 'true') {
            return <LoadingDot />
        }

        if (categoriesLoading === 'null') {
            return <div>Error retrieving data</div>
        }

        if (categoriesLoading === 'done') {
            function getGallery(category, preview) {
                if (imagesLoading === 'done') {
                    const images = Object.values(imagesContent).filter(i => i.categories).filter(i => 
                        i.categories.map(c => c.category).includes(category.id)
                        ).map(getContentThumbnail)
                    
                    if (images.length != 0) return <Gallery 
                        key={category.id}
                        onViewMore={() => onViewMore(category.id)} 
                        preview={preview}
                        title={category.title} >
                        {images}
                    </Gallery>
                }
            }

            const params = new URLSearchParams(history.location.search)
            const categoryId = params.get('category')

            if (searchState) {
                const images = 
                    Object.values(imagesContent)
                    .filter(i => i.title.toLowerCase().includes(searchState.toLowerCase()))
                    .map(getContentThumbnail)
                if (images.length == 0) return <div>No Images found</div>    
                else return <Gallery
                    title={`Your search for ${searchState} returned ${images.length} results`}
                    >{images}</Gallery> 
            }

            if (categoryId) return getGallery(categoriesContent[categoryId], false)

            else return (
                <>
                <CategoriesGallery 
                    categoriesLoading={categoriesLoading}
                    contentLoading={imagesLoading}
                    categories={categoriesContent}
                    content={imagesContent}/>
                {Object.values(categoriesContent)
                    .sort(c => c.title !== 'Featured')
                    .map(c => getGallery(c, true))}
                </>
            )
            }
        }

    return (
        <Container>
            {getCategory()}
        </Container>
    )
}
