import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import fetch from 'node-fetch'

import { trackAuthState } from './../firebase/Auth'
import { getUser, getContent, setValue } from './../firebase/Database'
import { instructureData } from '../Exports'
import ConfirmActionContext from '../context/ConfirmActionContext'
import GlobalStateContext from '../context/GlobalStateContext'
import NotificationsContext from '../context/NotificationsContext'
import ls from 'local-storage'

export function useIsLogged() {
    const [state, reducer] = useState({ isUserLogged: false, loggedUser: null, isLoading: true });

    useEffect(() => {
        function handleStateChange(user) {
            if (user) {
                function set() {
                    if (!state.isUserLogged) getUser(user.uid).then(u => {
                        if (u) { 
                            if (!u.id) {
                                u.id = user.uid
                                setValue('users', user.uid, { id: user.uid })
                            }
                            reducer({ isUserLogged: true, loggedUser: u, isLoading: false })
                        }
                        else {
                            // Create new user.
                            setValue('users', user.uid, {
                                id: user.uid,
                                email: user.email,
                                localId: user.uid,
                                userName: user.email
                            }).then(m => set())
                        }
                    })
                }
                set()
            } else {
                if (state.isUserLogged) reducer({ isUserLogged: false, loggedUser: null, isLoading: false })
            }
        }

        return trackAuthState(handleStateChange)
    });

    return state
}

export function useInstructureAuth(ask = true) {
    const confirmAction = useContext(ConfirmActionContext)
    const notifications = useContext(NotificationsContext)
    const globalState = useContext(GlobalStateContext)
    const code = useParam('code')
    const [state, setState] = useState({
        isLoading: true,
        tokenData: null,
        init: true,
        ask: () => {
            confirmAction.ask({
                title: 'Do you want to connect to CNG HUB?',
                confirmText: 'Connect',
                cancelText: 'Cancel',
                onConfirm: () => {
                    window.location.href = instructureData.loginUrl
                },
                onCancel: () => {
                    state.set()
                }
            })
        },
        set: v => setState({...state, isLoading: false, tokenData: v }),
        logout: () => {
            confirmAction.ask({
                title: 'Do you want to log out of CNG HUB?',
                confirmText: 'Log out',
                cancelText: 'Cancel',
                onConfirm: () => {
                    state.set()
                    ls.remove(localKey)
                    notifications.notify('Logged out of CNG HUB')
                }
            })
        }
    })

    const localKey = 'instructureTokenData'
    let savedTokenData = ls(localKey)

    const getToken = async() => {
        state.init = false
        setState({...state, init: false })

        if (!instructureData) state.set()

        // Use locally saved token.
        else if (savedTokenData) {
            if (savedTokenData.expireDate >= new Date()) {
                // Expired.
                const response = await fetch(`https://cors-anywhere.herokuapp.com/${instructureData.tokenUrl}`, {
                    method: 'post',
                    body: JSON.stringify(instructureData.getTokenFromRefreshData(savedTokenData.data.refresh_token)),
                    headers: { 'Content-Type': 'application/json' }
                })

                const json = await response.json()
                savedTokenData = { expireDate: new Date().setSeconds(json.expires_in), data: {...savedTokenData.data, access_token: json.access_token } }

                state.set(savedTokenData)
            } else {
                // All good.
                state.set(savedTokenData)
            }
        } else {
            // Use code.
            if (code) {
                const response = await fetch(`https://cors-anywhere.herokuapp.com/${instructureData.tokenUrl}`, {
                    method: 'post',
                    body: JSON.stringify(instructureData.getTokenFromCodeData(code)),
                    headers: { 'Content-Type': 'application/json' }
                })
                const json = await response.json()
                savedTokenData = { expireDate: new Date().setSeconds(json.expires_in), data: json }
                ls(localKey, savedTokenData)
                notifications.notify('Connected to CNG HUB')
                state.set(savedTokenData)
            }

            // Full login needed.
            else if (ask) state.ask()
            else state.set()
        }
    }

    try {
        if (state.init) {
            // globalState.setAskCanvasConnect(false)
            getToken()
        }
    } catch {
        state.set()
    }

    return state
}

export function useAccessInteractives() {
    const userState = useIsLogged()
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState('false')
    const [interactives, loadinginteractives] = useContent('experiences')

    useEffect(() => {
        try {
            setLoading('true')
                // Once the interactives are done downloading.
            if (loadinginteractives === 'done') {
                // First check if there is a user logged.
                if (userState.loggedUser) {
                    // If so, filter interactives by access Codes.
                    setResult(Object.fromEntries(Object.entries(interactives).filter(([k, v]) => {
                        // Video public.
                        if (!v.accessCodes) return true
                        else {
                            // The user has no accessCodes.
                            if (!userState.loggedUser.accessCodes) return false

                            // Check if the user has this interactives's code.
                            else {
                                for (let accessCode of v.accessCodes) {
                                    if (accessCode.accessCode in userState.loggedUser.accessCodes) return true
                                }

                                // It doesn't.
                                return false
                            }
                        }
                    })))
                }

                // No user logged, return only free access interactives.
                else setResult(Object.fromEntries(Object.entries(interactives).filter(([k, v]) => !v.accessCodes)))

                // Finish.
                setLoading('done')
            }
        } catch { setLoading('null') }
    }, [loadinginteractives, userState.loggedUser])

    return [result, loading]
}

export function useAccessVideos() {
    const userState = useIsLogged()
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState('false')
    const [videos, loadingVideos] = useContent('videos360')

    useEffect(() => {
        try {
            console.log(userState.loggedUser)
            setLoading('true')
                // Once the videos are done downloading.
            if (loadingVideos === 'done') {
                // First check if there is a user logged.
                if (userState.loggedUser) {
                    // If so, filter videos by access Codes.
                    setResult(Object.fromEntries(Object.entries(videos).filter(([k, v]) => {
                        // Video public.
                        if (!v.accessCodes) return true
                        else {
                            // The user has no accessCodes.
                            if (!userState.loggedUser.accessCodes) return false

                            // Check if the user has this video's code.
                            else {
                                for (let accessCode of v.accessCodes) {
                                    if (accessCode.accessCode in userState.loggedUser.accessCodes) return true
                                }

                                // It doesn't.
                                return false
                            }
                        }
                    })))
                }

                // No user logged, return only free access videos.
                else setResult(Object.fromEntries(Object.entries(videos).filter(([k, v]) => !v.accessCodes)))

                // Finish.
                setLoading('done')
            }
        } catch { setLoading('null') }
    }, [loadingVideos, userState.loggedUser])

    return [result, loading]
}

export function useContent(content) {
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState('false')

    useEffect(() => {
        async function fetchContent() {
            try {
                setLoading('true')
                const response = await getContent(content)
                setResult(response)
                setLoading('done')
            } catch {
                setLoading('null')
            }
        }

        if (content !== '') fetchContent()
    }, [content])

    return [result, loading]
}

export function useGetElement(content, id) {
    const [result, setResult] = useState({})
    const [loading, setLoading] = useState('false')

    useEffect(() => {
        async function fetchElement() {
            try {
                setLoading('true')
                const response = await getContent(content)
                setResult(response[id])
                setLoading('done')
            } catch { setLoading('null') }
        }

        if (id !== '') fetchElement()
    }, [id, content])

    return [result, loading]
}

export function useParam(param) {
    const history = useHistory()
    const params = new URLSearchParams(history.location.search)
    return params.get(param)
}