import React, { useState } from 'react'

const GlobalState = React.createContext('')

export function GlobalStateContext(props) {
    const [state, setState] = useState({
        askCanvasConnect: true,
        setAskCanvasConnect: v => { state.askCanvasConnect = v; state.save(); },
        save: () => setState({...state})
    })

    return <GlobalState.Provider value={state}>{props.children}</GlobalState.Provider>
}

export default GlobalState