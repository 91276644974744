import flamelink from 'flamelink'
import 'flamelink/content'
import 'flamelink/storage'

import { firebaseApp } from './Auth'

const app = flamelink({
    firebaseApp,
    env: 'production', // optional, defaults to `production`
    locale: 'en-US', // optional, defaults to `en-US`
    dbType: 'rtdb'
  })

export const getContent = content => new Promise((resolve, reject) => {
    app.content.get({schemaKey: content}).then(response => {
        resolve(response)
    }).catch(error => reject(error))
})

export const getUrl = id => new Promise((resolve, reject) => {
    app.storage.getURL({fileId: id}).then(url => resolve(url))
    .catch(error => reject(error))
})

export const getUser = id => new Promise((resolve, reject) => {
    getContent('users').then(users => {
        resolve(users[id])
    }).catch(error => reject(error))
})

export const getElement = (content, id) => new Promise((resolve, reject) => {
    getContent(content).then(response => resolve(response[id])).catch(error => reject(error))
})

// Payload example { views: 432 }
export const setValue = (content, id, payload) => new Promise((resolve, reject) => {
    if (!content || !id || !payload) {
        reject('null')
        return
    }
    app.content.update({
        schemaKey: content,
        entryId: id,
        data: payload
    })
        .then(resolve('Updating the entry succeeded'))
        .catch(reject);
})

// app.content.get({ schemaKey: 'videos360' })
// .then(products => {
//     app.storage.getURL({ fileId:  Object.values(products)[0].thumbnail[0]}).then(url => console.log(url))
// })
// .catch(error => console.log(error))