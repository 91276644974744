import React, { useState } from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';

import ImageCanvas from './../components/ImageCanvas'
import LoadingDot from '../components/LoadingDot'
import SharePanel from '../components/SharePanel'
import IconButton from './../components/IconButton'
import shareIcon from './../graphics/icons/share.svg'

import { useGetElement, useParam } from './../hooks/CustomHooks'
import { getUrl } from './../firebase/Database'

const Container = styled.div`
`

const Title = styled.div`
    color: ${p => p.theme.medium};
    margin-top: .5em;
    font-weight: bold;
    font-size: 1.2em;
`

const Description = styled.div`
    margin-top: 1em;
    color: ${p => p.theme.gray};
    border-top: .09em solid ${p => p.theme.gray};
    padding-top: 1em;
`

const Row = styled.div`
    display: grid;
    grid-gap: 1em;
    grid-template-columns: min-content auto min-content;
    padding: .8em 0;
`

export default function ImageDisplay() {
    const imageId = useParam('image')
    const [image, imageLoading] = useGetElement('images360', imageId) 
    const [imageUrl, setImageUrl] = useState('')
    const [sharePanel, setSharePanel] = useState(false)

    window.scrollTo(0,0);
    const getImagePath = () => image.stereoscopic ? '360ImageMono' : '360Image'
    if (imageLoading === 'done' && imageUrl === '') getUrl(image[getImagePath()][0]).then(setImageUrl)

    return (
        <Container>
            {imageUrl ? 
            <Reveal effect='fadeInUp' duration={500}>
                <ImageCanvas image={imageUrl}/>
                <Row>
                    <Title>{image.title}</Title>
                    <div />
                    <IconButton small dark horizontal icon={shareIcon} onClick={() => setSharePanel(true)}>Share</IconButton>
                </Row>
                <Description>{image.description}</Description>
            </Reveal> : <LoadingDot />
            }
            {sharePanel && <SharePanel videoUrl={imageUrl} onClose={() => setSharePanel(false)} />}
        </Container>
    )
}