import React, { useContext } from 'react'
import styled from 'styled-components'

import { 
    EmailShareButton, 
    EmailIcon,
    FacebookShareButton, 
    FacebookIcon,
    TwitterShareButton, 
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
    RedditShareButton,
    RedditIcon,
} from "react-share"; 

import Popup from './Popup'
import Button from './Button'
import TextButton from './TextButton'
import QR from './QR'

import { copyToClipboard } from '../utility'

import Notifications from '../context/NotificationsContext'

export default function SharePanel(props) {
    const getEmbed = () => {
        if (props.videoId) return `<iframe src='${`https://${window.location.host}/embed?video=${props.videoId}`}' scrolling="no" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
        if (props.appUrl) return `<iframe src='${props.appUrl}' scrolling="no" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
    }

    return (
        <Popup onClose={props.onClose}>
            <Container>
                <Title>Share</Title>
                <CopyLink title='Link' style={{gridColumn: '1/2', gridRow: '3/4'}} link={window.location.href}></CopyLink>
                <CopyLink title='Embed' style={{gridColumn: '1/2', gridRow: '4/5'}} link={getEmbed()}></CopyLink>
                <QR style={{gridColumn: '2/3', gridRow: '3/5'}} link={window.location.href}/>
                <SocialButtons>
                    <TwitterShareButton url={window.location.href}><TwitterIcon round/></TwitterShareButton>
                    <FacebookShareButton url={window.location.href}><FacebookIcon round/></FacebookShareButton>
                    <WhatsappShareButton url={window.location.href}><WhatsappIcon round/></WhatsappShareButton>
                    <EmailShareButton url={window.location.href}><EmailIcon round/></EmailShareButton>
                    <LinkedinShareButton url={window.location.href}><LinkedinIcon round/></LinkedinShareButton>
                    <RedditShareButton url={window.location.href}><RedditIcon round/></RedditShareButton>
                </SocialButtons>
            </Container>
        </Popup>
    )
}

function CopyLink(props) {
    const notifications = useContext(Notifications)
    const copyHandler = () => {
        copyToClipboard(props.link)
        notifications.notify('Copied to clipboard')
    }
    return (
        <CopyLinkContainer style={props.style}>
            <SubTitle>{props.title}</SubTitle>
            <TextButton onClick={copyHandler} style={{gridColumn: '1/2', gridRow: '2/3'}}>{props.link}</TextButton>
            <Button onClick={copyHandler} height='1.5rem' style={{gridColumn: '2/3', gridRow: '2/3'}}>Copy</Button>
        </CopyLinkContainer>
    )
}

const SocialButtons = styled.div`
    grid-column: 1/3;
    grid-row: 2/3;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2rem;
    width: min-content;
`

const SubTitle = styled.div`
    font-weight: bold;
`

const CopyLinkContainer = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: auto auto;
    grid-column-gap: 1rem;
`

const Container = styled.div`
    padding: 1rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-rows: 2rem 4rem 4rem 4rem;
    grid-template-columns: 30rem 20rem;
`

const Title = styled.div`
    grid-column: 1/3;
    grid-row: 1/2;
    font-weight: bold;
    font-size: 1.3rem;
`