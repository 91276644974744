import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Shake } from 'reshake'

import Logo from './../components/Logo'
import Button from './../components/Button'
import InputField from './../components/InputField'
import LoadingDot from '../components/LoadingDot'

import ConfirmAction from '../context/ConfirmActionContext'
import Notifications from '../context/NotificationsContext'

import { login, sendResetPasswordEmail } from './../firebase/Auth'

import { brand } from '../Exports'

const Container = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1em;
    width: 15rem;
`

const Title = styled.div`
    color: ${p => p.theme.medium};
    text-align: center;
`

const TextButton = styled.div`
    color: ${p => p.theme.primary};
    text-align: center;
    cursor: pointer;
    :hover {
        color: ${p => p.theme.primaryHover};
    }
    font-weight: bold;
    padding: 1em 0;
    border-bottom: .1rem solid ${p => p.theme.darkerLight};
`

const LogoContainer = styled.div`
    height: 4em;
`

const Description = styled.div`
    color: ${p => p.theme.medium};
    text-align: center;
    font-size: .8rem;
`

export default function Login(props) {
    const confirmAction = useContext(ConfirmAction)
    const notifications = useContext(Notifications)

    const [state, setState] = useState({
        emailField: '',
        setEmail: v => {state.emailField = v; state.save(); },
        passwordField: '',
        setPassword: v => {state.passwordField = v; state.save(); },
        isLoading: false,
        setIsLoading: v => { state.isLoading = v; state.save(); },
        errorMessage: '',
        setErrorMessage: v => { state.errorMessage = v; state.save(); },
        isShakeing: false,
        setIsShakeing: v => { state.isShakeing = v; state.save(); },
        shake: () => { state.setIsShakeing(true); setTimeout(() => { state.setIsShakeing(false) }, 200); },
        save: () => setState({...state})
    })

    const loginHandler = () => {
        state.setErrorMessage('')
        login(
            {email:state.emailField, password:state.passwordField}, // Credentials
            callback => {
                if (callback.status === 0) { // Success
                    props.loginHandler()
                }

                else { // Error
                    state.shake()
                    state.setErrorMessage(callback.error.message)
                }

                state.setIsLoading(false)
            }
        )
        state.setIsLoading(true)
    }

    const forgotPasswordHandler = () => {
        confirmAction.ask({
            title: 'Please enter your email, and a reset password link will be send',
            confirmText: 'Send',
            placeholder: 'example@example.com',
            onTextConfirm: v => {
                if (v) sendResetPasswordEmail(v).then(() => {
                    notifications.notify(`email sent to ${v}`)
                }).catch(e => notifications.notify(e.message))
            }
        })
    }

    const container = (
        <Container>
            <Title>Welcome to</Title>
            <LogoContainer><Logo/></LogoContainer>
            <InputField type='email' value={state.emailField} onValueChange={state.setEmail} height='2.3rem' placeholder='Email'/>
            <InputField type='password' value={state.passwordField} onValueChange={state.setPassword} height='2.3rem' placeholder='Password'/>
            {state.isLoading ? <LoadingDot paddingTop='0'/> :
            <Button onClick={loginHandler} primary height='2.3rem'>Sign In</Button> }
            {state.errorMessage && <Description>{state.errorMessage}</Description>}
            <TextButton onClick={forgotPasswordHandler}>I forgot my password</TextButton>
            <Button onClick={props.signUpHandler} height='2.3rem'>Sign Up</Button>
            <Description>
                {`Register to get access to premium ${brand.title} content and features.`}
            </Description>
        </Container>
    )

    return (
        <>
        {
            state.isShakeing ? <Shake h={9} dur={300} int={1} max={100} fixed={true}>{container}</Shake> : container
        }
        </>
    )
}