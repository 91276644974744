import React from 'react'
import styled, { css } from 'styled-components'


const Icon = styled.div`
    background-color: ${p => p.dark ? p.theme.medium : p.primary ? p.theme.primary : p.theme.gray};
    -webkit-mask-image: url(${p => p.icon});
    mask-image: url(${p => p.icon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
`

const Text = styled.div`
    color: ${p => p.dark ? p.theme.medium : p.primary ? p.theme.primary : p.theme.gray};
    display: flex;
    justify-content: center;
    align-items: center;

    ${p => !p.horizontal && css`
        white-space: nowrap;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    `}
`

const Container = styled.div`
    height: ${p => p.small ? '1em' : '4em'};
    display: grid;
    grid-gap: .5em;
    ${p => p.horizontal && css`
        grid-template-columns: 2em auto;
    `}
    ${p => !p.horizontal && css`
        grid-template-rows: 2fr 1fr;
    `}
    cursor: pointer;

    :hover {
        ${Icon} {
            background-color: ${p => p.theme.primary};
        }
        ${Text} {
            color: ${p => p.theme.primary};
        }
    }
`

export default function IconButton(props) {
    return (
        <Container style={props.style} onClick={props.onClick} small={props.small} horizontal={props.horizontal}>
            <Icon primary={props.primary} dark={props.dark} icon={props.icon}/>
            <Text primary={props.primary} horizontal={props.horizontal} dark={props.dark}>{props.children}</Text>
        </Container>
    )
}