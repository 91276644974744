import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { PannellumVideo } from "pannellum-react";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;;
    background-color: ${p => p.theme.medium};
`

const AspectRationBox = styled.div`
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 591.44px / 1127.34px * 100%;
    padding-top: 56.25%;
    position: relative;
`

export default function VideoPlayer(props) {
    const videoRef = useRef()
    const video = <PannellumVideo ref={videoRef} height='100%' controls muted={false} video={props.video} autoplay={!props.custom}/>

    useEffect(() => {
        if (videoRef.current && videoRef.current.videoNode) {
            videoRef.current.videoNode.onplay = props.onPlay
            videoRef.current.videoNode.onpause = props.onPause
        }
    }, [videoRef.current])

    const content = props.custom ? video : (
        <AspectRationBox> <Container> {video} </Container> </AspectRationBox>
    )

    return content
}
