import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';

import VideoPlayer from './../components/VideoPlayer'
import IconButton from './../components/IconButton'
import LoadingDot from '../components/LoadingDot'

import favoriteIcon from './../graphics/icons/favorite.svg'
import shareIcon from './../graphics/icons/share.svg'

import Gallery from '../components/Gallery'
import ContentThumbnail from '../components/ContentThumbnail'
import SharePanel from '../components/SharePanel'

import { useIsLogged, useContent, useParam, useAccessVideos } from './../hooks/CustomHooks'
import { getUrl, getElement, setValue } from './../firebase/Database'
import Notifications from '../context/NotificationsContext'

import { brand } from '../Exports'

const Container = styled.div`
    width: 100%;
    height: 100%;
`

const Title = styled.div`
    color: ${p => p.theme.medium};
    margin-top: .5em;
    font-weight: bold;
    font-size: 1.2em;
`

const UserRow = styled.div`
    margin-top: .5em;
    display: grid;
    grid-auto-flow: column;
    height: 2em;
    width: min-content;
    grid-gap: .5em;
`

const Row = styled.div`
    display: grid;
    grid-gap: 1em;
    grid-template-columns: auto min-content min-content;
    border-bottom: .08em solid ${p => p.theme.gray};
    padding: .8em 0;
`

const Text = styled.div`
    color: ${p => p.theme.medium};
    text-align: left;
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-weight: bold;
`

const UserIcon = styled.div`
    width: 2em;
    background-image: url(${p => p.image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${p => p.theme.roundCorners};
`

const Description = styled.div`
    margin-top: 1em;
    color: ${p => p.theme.gray};
`

export default function ContentPlayer(props) {
    const userState = useIsLogged()
    const notifications = useContext(Notifications)
    const videoId = useParam('video')
    const [video, setVideo] = useState()
    const [videos, videosLoading] = useContent('videos360')
    const [videoUrl, setVideoUrl] = useState('')
    const [author, setAuthor] = useState()
    const [authorUrl, setAuthorUrl] = useState('')
    const [authorVideos, setAuthorVideos] = useState()
    const [isVideoLiked, setIsVideoLiked] = useState(false)
    const [sharePanel, setSharePanel] = useState(false)

    if (videosLoading === 'done' && (!video || video.id != videoId)) {
        setAuthor()
        setAuthorVideos()
        setAuthorUrl()
        var v = videos[videoId]
        setVideo(v)
        console.log(v)
        var path = v.sdVideo ? Object.values(v.sdVideo)[0] : Object.values(v.hdVideo)[0]
        getUrl(path).then(setVideoUrl)
        getUrl(path).then(console.log)
    }

    const getAuthorId = () => {
        if (video.author) return video.author
        else return brand.defaultAuthor
    }

    if (video && !author) getElement('authors', getAuthorId()).then(a => {
        setValue('videos360', videoId, {views: video.views + 1}).then(console.log).catch(console.log) // Increment views
        if (!a) return;
        setAuthor(a)
        getUrl(Object.values(a.logo)[0]).then(setAuthorUrl)
        setAuthorVideos(Object.values(videos).filter(v => (v.author && v.author === a.id)))
    })

    const getThumbnail = v => <ContentThumbnail 
        key={v.id}
        link={`player?video=${v.id}`}
        id={v.id}
        title={v.title}
        description={v.description}
        thumbnailImage={v.thumbnail}
        author={v.author}
        views={v.views}
        duration={v.duration}
        />

    const getFavoriteVideos = () => {
        if (!userState.loggedUser || !userState.loggedUser.favoriteVideos || !videos) return <></>

        return <Gallery preview title='Your Favourites'>
            {Object.values(videos)
                .filter(v => v.id in userState.loggedUser.favoriteVideos)
                .map(getThumbnail)}
        </Gallery>
    }

    const getAuthorVideos = () => {
        if (authorVideos) {
            return <Gallery preview title={`More videos from ${author.name}`}>
                {authorVideos.map(getThumbnail)}
            </Gallery>
        }

        if (video && !video.author) return <></>

        return <LoadingDot />
    }

    const favoriteHandler = () => {
        if (!video || !userState.isUserLogged) return
        if (!userState.loggedUser.favoriteVideos) userState.loggedUser.favoriteVideos = {}
        if (video.id in userState.loggedUser.favoriteVideos) { 
            delete userState.loggedUser.favoriteVideos[video.id]
            setIsVideoLiked(false)
        }
        else { 
            userState.loggedUser.favoriteVideos[video.id] = video.id
            notifications.notify('You liked the video')
            setIsVideoLiked(true)
        }

        setValue('users', userState.loggedUser.id, {favoriteVideos: userState.loggedUser.favoriteVideos})
    }

    const getIsLikedByUser = () => {
        if (!userState.loggedUser || !video || !userState.loggedUser.favoriteVideos) return false
        return video.id in userState.loggedUser.favoriteVideos
    }

    const isVideoLikedByUser = getIsLikedByUser()
    if (isVideoLikedByUser != isVideoLiked) setIsVideoLiked(isVideoLikedByUser)

    window.scrollTo(0,0);
    return (
        <Container>
            {
            videoUrl ? 
            <Reveal effect='fadeInUp' duration={500}>
            <VideoPlayer video={videoUrl}/>
            <Title>{video.title}</Title>
            <UserRow>
                <UserIcon image={authorUrl}/>
                <Text>{author && author.name}</Text>
            </UserRow>
            <Row>
                <Text>{video.views + 1} Views</Text>
                {userState.isUserLogged && 
                <IconButton small primary={isVideoLiked} dark={!isVideoLiked} horizontal icon={favoriteIcon} onClick={favoriteHandler}>Favourite</IconButton>}
                <IconButton small dark horizontal icon={shareIcon} onClick={() => setSharePanel(true)}>Share</IconButton>
            </Row>
            <Description>{video.description}</Description>
            {getAuthorVideos()}
            {getFavoriteVideos()}
            </Reveal>
            : <LoadingDot />}

            {sharePanel && <SharePanel videoId={videoId} onClose={() => setSharePanel(false)} />}
        </Container>
    )
}