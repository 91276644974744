// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'


// Firebase config.
import { firebaseConfig } from '../Exports'

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);

export function login(credentials, callback) {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL) // Save user locally.
    .then(() => firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password) // Login with email/password
    .then(u => callback({status:0, user:u}))) // Callback successfully with user.
    .catch(error => callback({status:1, error: error})); // Callback error.
}

export function register(credentials, callback) {
    firebase.auth().createUserWithEmailAndPassword(credentials.email, credentials.password)
    .then(response => {
        callback({status:0, response: response})
    })
    .catch(error => { callback({status:1, error: error})})
}

export function logout(callback) {
    firebase.auth().signOut().then(callback).catch(callback)
}

export const trackAuthState = (observer) => { 
    return firebase.auth().onAuthStateChanged(observer)
}

export const sendResetPasswordEmail = email => new Promise((resolve, reject) => {
    firebase.auth().sendPasswordResetEmail(email).then(resolve).catch(reject);
})