import React, { useState } from 'react'
import { key as generateKey } from 'firebase-key'

const Notifications = React.createContext({})

export function NotificationsContext(props) {
    const [state, reducer] = useState({
        notify: t => { 
            let key = generateKey()
            state.notifications[key] = {
                text: t,
                state: true
            } 
            setTimeout(() => {
                state.notifications[key].state = false
                state.save()
                setTimeout(() => {
                    delete state.notifications[key]
                    state.save()
                }, 1000)
            }, 3000)
            state.save()
        },

        notifications: {},
        save: () => reducer({...state})
    })

    return (
        <Notifications.Provider value={state}>
            { props.children }
        </Notifications.Provider>
    )
}

export default Notifications