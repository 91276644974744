import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/animation.css'
import App from './App';

// Theme.
import { ThemeProvider } from 'styled-components'
import { Theme } from './Exports'

// Context.
import { SearchContext } from './context/SearchContext'
import { ConfirmActionContext } from './context/ConfirmActionContext'
import { NotificationsContext } from './context/NotificationsContext'
import { GlobalStateContext } from './context/GlobalStateContext'

function Container() {
    return (
        <ThemeProvider theme={ Theme }>
            <GlobalStateContext>
                <NotificationsContext>
                    <ConfirmActionContext>
                        <SearchContext>
                            <App />
                        </SearchContext>
                    </ConfirmActionContext>
                </NotificationsContext>
            </GlobalStateContext>
        </ThemeProvider>
    )
}

ReactDOM.render(<Container />, document.getElementById('root'));