import React, { useState } from 'react'
import styled from 'styled-components'

import { useParam, useGetElement, useInstructureAuth } from './../hooks/CustomHooks'
import LoadingDot from '../components/LoadingDot'
import SharePanel from '../components/SharePanel'
import shareIcon from './../graphics/icons/share.svg'
import { instructureData } from '../theme/Health'

const Container = styled.div`
    position: absolute;
    top: 5em;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: ${p => p.theme.light};
`

const Frame = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
`

const ShareIconContainer = styled.div`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 3rem;
    width: 3rem;
    background-color: ${p => p.theme.light};
    transition: .3s;
    padding: .5rem;
    border-radius: ${p => p.theme.roundCorners};
    :hover {
        transform: translateY(-.3rem);
        * {
            background-color: ${p => p.theme.primary};
        }
    }
    cursor: pointer;
`

const ShareIcon = styled.div`
    height: 100%;
    background-color: ${p => p.theme.medium};
    -webkit-mask-image: url(${shareIcon});
    mask-image: url(${shareIcon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
`

export default function InteractivePlayer(props) {
    const id = useParam('app')
    const [app, appLoading] = useGetElement('experiences', id)
    const instructureState = useInstructureAuth()
    const [sharePanel, setSharePanel] = useState(false)

    const getParams = () => {
        if (instructureState.tokenData && instructureState.tokenData.data) return `?logged=true&access_token=${instructureState.tokenData.data.access_token}&refresh_token=${instructureState.tokenData.data.refresh_token}&expire_date=${instructureState.tokenData.expireDate}` 
        else return `?logged=false`
    }

    return (
        <Container>
            <ShareIconContainer onClick={() => setSharePanel(true)}><ShareIcon/></ShareIconContainer>
            {(app && app.unityUrl && !instructureState.isLoading) ? <Frame src={`${app.unityUrl}${getParams()}`}/> : <LoadingDot />}
            {sharePanel && <SharePanel appUrl={app.unityUrl} onClose={() => setSharePanel(false)} />}
        </Container>
    )
}