import aboutImage from '../graphics/about.jpg'

import logoA from '../graphics/brand/FarmVRIcon.png'
import logoB from '../graphics/brand/ThinkDigitalVRWhite.png'
import logoC from '../graphics/logos/logoA.png'


// Think Digital
export const Theme = {
    primary: '#00aeef',
    primaryHover: '#88e3ff',
    alternative: '#23ffe3',
    light: '#ffff',
    darkerLight: '#f8f8f8',
    dark: '#272838',
    gray: '#969696',
    medium: '#4d4d4d',

    // roundCorners: '.5em',
    // rounderCorners: '1.5em',
    roundCorners: '.1em',
    rounderCorners: '.5em',
    shadow: '0 .1em .2em rgb(1,1,1,.2)',

    mobile: '600px',
    tablet: '1138px',
}

export const brand = {
    getTheAppFirstDescription: `Access the FarmVR content across your virtual reality headsets. With over 50 experiences across more than 10 categories including beef, sheep & wool, aquaculture and pork, there's something for everyone`,
    getTheAppSecondDescription: `Immerse yourself in the FarmVR library with over 50 agriculutral experiences using your iOS or Android device. Compatible with Google Cardboard, but does not require a VR headset`,
    getTheAppShortDescription: `Using Immersive Technologies to Enhance Agricultural Education`,

    manifesto: 'We use techonology to bring together individuals, businesses and communities so they can collaborate, share knowledge and build skills. We seek to empower and educate those around us so they can in turn create their own stories to share and inspire.',
    aboutImage: aboutImage,
    textLogo: 'THINK.DIGITAL',

    logoA: logoA,
    logoB: logoB,
    logoC: logoC,

    title: 'Farm VR',
    defaultAuthor: '1553600127858',

    // Links
    webUrl: 'https://farmvr.com/',
    about: 'https://farmvr.com/',
    emailUrl: 'info@farmvr.com',
    privacyPolicy: 'https://farmvr.com/',
    contactUs: 'https://farmvr.com/contact/',

    oculusQuestLink: 'https://www.oculus.com/experiences/go/2255622867842087/?locale=en_US',
    oculusLink: 'https://www.oculus.com/experiences/go/2255622867842087/?locale=en_US',
    oculusGoLink: 'https://www.oculus.com/experiences/go/2255622867842087/?locale=en_US',
    viveLink: 'https://www.oculus.com/experiences/go/2255622867842087/?locale=en_US',
    appStoreLink: 'https://apps.apple.com/au/app/farmvr/id1257848466',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.thinkdigital.farmvr&hl=en',
}

export const firebaseConfig = {
    apiKey: "AIzaSyCS3jqSFM1P9b7IIbDzcZzU9aniH5qUCLA",
    authDomain: "farmvr-74d6a.firebaseapp.com",
    databaseURL: "https://farmvr-74d6a.firebaseio.com",
    projectId: "farmvr-74d6a",
    storageBucket: "gs://farmvr-74d6a.appspot.com",
    messagingSenderId: "769608025856",
    appId: "1:769608025856:web:5310149ce0cb637f7495e5"
};

export const instructureData = undefined