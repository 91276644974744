import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Search from '../../context/SearchContext'

import Gallery from '../../components/Gallery'
import ContentThumbnail from './../../components/ContentThumbnail'
import CategoriesGallery from '../../components/CategoriesGallery'
import LoadingDot from '../../components/LoadingDot'

import { useContent, useAccessVideos } from './../../hooks/CustomHooks'

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
`

export default function Experience(props) {
    const history = useHistory()
    const [searchState] = useContext(Search) 
    const [categoriesContent, categoriesLoading] = useContent('categories')
    const [videosContent, videosLoading] = useAccessVideos()

    const onViewMore = category => {
        history.push({search: `?category=${category}`})
        window.scrollTo(0, 0);
    }

    const getCategory = () => {
        const getContentThumbnail = v => 
            <ContentThumbnail
            key={v.id}
            link={`player?video=${v.id}`}
            id={v.id}
            title={v.title}
            description={v.description}
            thumbnailImage={v.thumbnail}
            author={v.author}
            views={v.views}
            duration={v.duration}
            />

        if (categoriesLoading === 'true') {
            return <LoadingDot />
        }

        if (categoriesLoading === 'null') {
            return <div>Error recieving data</div>
        }

        if (categoriesLoading === 'done') {
            function getGallery(category, preview) {
                if (videosLoading === 'done') {
                    const videos = Object.values(videosContent).filter(i => i.categories).filter(v => 
                        v.categories.map(c => c.category).includes(category.id)
                    ).map(getContentThumbnail)
                    if (videos.length != 0) return <Gallery 
                    key={category.id}
                    onViewMore={() => onViewMore(category.id)} 
                    preview={preview}
                    title={category.title}>
                        {videos}
                    </Gallery>
                }
            }

        const params = new URLSearchParams(history.location.search)
        const categoryId = params.get('category')

        if (searchState) {
            const videos = 
                Object.values(videosContent)
                .filter(v => v.title.toLowerCase().includes(searchState.toLowerCase()))
                .map(getContentThumbnail)
            if (videos.length == 0) return <div>No Videos found</div>    
            else return <Gallery
                title={`Your search for ${searchState} returned ${videos.length} results`}
                >{videos}</Gallery> 
        }

        if (categoryId) return getGallery(categoriesContent[categoryId], false)

        else return (
            <>
            <CategoriesGallery 
                categoriesLoading={categoriesLoading}
                contentLoading={videosLoading}
                categories={categoriesContent}
                content={videosContent}/>
            {Object.values(categoriesContent)
                .sort((a, b) => a.order > b.order ? 1 : -1)
                .map(c => getGallery(c, true))}
            </>
        )
    }
}

    return (
        <Container>
            {getCategory()}
        </Container>
    )
}