import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Reveal from 'react-reveal/Reveal';
import { useHistory } from 'react-router-dom'

import { getUrl } from '../firebase/Database'

import arrowIcon from '../graphics/icons/arrow.svg'
import IconButton from './IconButton'

import defaultIcon from '../graphics/categories/featured.svg'

const Container = styled.div`
    position: relative;
    margin: 1em 0;
    padding: 1em;
    border-bottom: .13em solid ${p => p.theme.darkerLight};
    max-width: 65em;
    @media only screen and (max-width: ${p => p.theme.mobile}) {
        max-width: 80vw;
    }
`

const ButtonHolder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
`

const Arrow = styled.div`
    & > * {
        margin: auto;
        width: 1rem;
        height: 1rem;
        background-color: ${p => p.theme.medium};
        -webkit-mask-image: url(${arrowIcon});
        mask-image: url(${arrowIcon});
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;

        ${p => !p.right && css`
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        `}
    }

    display: flex;
    align-items: center;

    pointer-events: all;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${p => p.theme.light};
    /* box-shadow: ${p => p.theme.shadow}; */
    font-weight: bold;
    font-size: 2rem;
    position: absolute;
    margin-top: -1rem;
    top: 50%;
    ${p => p.right && css`
        right: 0;
        margin-right: -1.6rem;
    `}
    ${p => !p.right && css`
        left: 0;
        margin-left: -1.6rem;
    `}
    cursor: pointer;
    :hover {
        /* background-color: ${p => p.theme.primary}; */
        & > * {
            background-color: ${p => p.theme.primary};
        }
    }
`

const Layout = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 8rem;
    grid-gap: 1rem;

    overflow: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { display: none;  /* Safari and Chrome */ }
`

function CategoryIcon(props) {
    const history = useHistory()
    const [url, setUrl] = useState()

    if (!url && props.category.svg) getUrl(props.category.svg).then(setUrl)

    const clickHandler = () => {
        history.push({search: `?category=${props.category.id}`})
    }

    return (<IconButton
        onClick={clickHandler}
        icon={url ? url : props.category.svg ? '' : defaultIcon}>
        {props.category.title}</IconButton>)
}

export default function CategoriesGallery(props) {
    const scroll = useRef()

    const getCategories = () => {
        if (props.categoriesLoading !== 'done' && props.contentLoading !== 'done') return;

        // Only categories with items in it.
        return Object.values(props.categories).filter(category => 
            Object.values(props.content).filter(i => i.categories).filter(i => 
                i.categories.map(c => c.category).includes(category.id)).length != 0)
                    .sort((a, b) => a.order > b.order ? 1 : -1)
                    .map(c => <CategoryIcon key={c.id} category={c}/>)
    }
                
    const scrollClickHandler = right => {
        scroll.current.scrollLeft += right ? 800 : -800; 
    }

    return (
        <Reveal effect='fadeInUp' duration={500}>
            <Container>
                <Layout ref={scroll}>{ getCategories() }</Layout>
                <ButtonHolder>
                    <Arrow right onClick={() => scrollClickHandler(true)}><div/></Arrow>
                    <Arrow onClick={() => scrollClickHandler(false)}><div/></Arrow>
                </ButtonHolder>
            </Container>
        </Reveal>
    )
}