import React from 'react'
import styled from 'styled-components'

import LoadingDot from '../../components/LoadingDot'
import Gallery from '../../components/Gallery'
import ContentThumbnail from '../../components/ContentThumbnail'
import Button from '../../components/Button'

import { useAccessInteractives, useInstructureAuth } from '../../hooks/CustomHooks'
import { instructureData } from '../../Exports'

import canvasLogo from '../../graphics/canvasLogo.png'

const Container = styled.div`
`

const CanvasConnect = styled.div`
    position: fixed;
    height: 3rem;
    display: grid;
    grid-auto-flow: column;
    width: min-content;

    top: 6rem;
    /* right: 2rem; */
    right: 0;
    margin-right: 2rem;
    z-index: 1;
`

const Logo = styled.div`
    width: 8rem;
    height: 100%;
    background-image: url(${canvasLogo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
`

const Text = styled.div`
    white-space: nowrap;
    margin: auto;
    color: ${p => p.theme.primary};
`

export default function Interactive() {
    const [content, loading] = useAccessInteractives()
    const instructureState = useInstructureAuth(false)

    const connectHandler = () => {
        if (instructureState.tokenData) instructureState.logout() 
        else instructureState.ask()
    }

    const getContent = () => {
        if (loading === 'done') {
            if (!content) return <Gallery title='No content'/>
            return (<Gallery title='Select your Activity'>
                {Object.values(content).map(i => 
                <ContentThumbnail
                    key={i.id}
                    id={i.id}
                    link={`activityplayer?app=${i.id}`}
                    title={i.title} 
                    description={i.description}
                    thumbnailImage={i.thumbnail}
                    author={i.author}
                />
                )}
            </Gallery>)
        }

        else return <LoadingDot />
    }
    return (
        <Container>
            {
            instructureData &&
            <CanvasConnect>
                <Button style={{backgroundColor: '#384765'}} onClick={connectHandler}>
                    {`${instructureState.tokenData ? 'Sign Out of ' : 'Sign In to '}  CNG HUB`}
                </Button>
            </CanvasConnect>
            }
            {getContent()}
        </Container>
    )
}