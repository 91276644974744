import React from 'react'
import styled from 'styled-components'
import { Pannellum } from "pannellum-react";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;;
    background-color: ${p => p.theme.medium};
`

const AspectRationBox = styled.div`
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 591.44px / 1127.34px * 100%;
    padding-top: 56.25%;
    position: relative;
`

export default function ImageCanvas(props) {
    return (
        <AspectRationBox>
            <Container>
                <Pannellum height='100%' autoLoad image={props.image}/>
            </Container>
        </AspectRationBox>
    )
}
