import React from 'react'
import styled, { keyframes, css } from 'styled-components'

const Container = styled.div`
    width: 100%;
    padding-top: ${p => p.paddingTop ? p.paddingTop : '5rem'};
`

const SubContainer = styled.div`
    width: 72px;
    margin: auto;
`

const Animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const Ring = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    & > div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 44px;
        height: 44px;
        margin: 8px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: ${Animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${p => p.theme.primary} transparent transparent transparent;
    }

    & > div:nth-child(1) { animation-delay: -0.45s; }
    & > div:nth-child(2) { animation-delay: -0.3s; }
    & > div:nth-child(3) { animation-delay: -0.15s; }
`

export default function LoadingDot(props) {
    return (
        <Container paddingTop={props.paddingTop}><SubContainer>
            <Ring>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Ring>
        </SubContainer></Container>
    )
}
