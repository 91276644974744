import aboutImage from '../graphics/home-banner.jpg'

import logoA from './../graphics/brand/hcLogoText.png'
import logoB from './../graphics/brand/hcWhite.svg'
import logoC from './../graphics/brand/hcLogo.png'

// HealthVR
export const Theme = {
    primary: '#33A96A',
    primaryHover: '#8FBE54',
    alternative: '#23ffe3',
    light: '#ffff',
    // darkerLight: '#e6e6e6',
    darkerLight: '#f8f8f8',
    dark: '#272838',
    // gray: '#b2b2b2',
    gray: '#969696',
    medium: '#4d4d4d',

    // roundCorners: '.5em',
    // rounderCorners: '1.5em',
    roundCorners: '.1em',
    rounderCorners: '.5em',
    shadow: '0 .1em .2em rgb(1,1,1,.2)',

    mobile: '600px',
    tablet: '1138px',
}

export const brand = {
    getTheAppFirstDescription: `Access the HealthConnectVR content across all of your virtual reality headsets.`,
    getTheAppSecondDescription: `Immerse yourself in the HealthConnectVR library using your iOS or android device. Compatible with Google Cardboard, but does not require a VR headset.`,
    getTheAppShortDescription: `Using Immersive Technologies to Enhance Health Education`,

    manifesto: 'At Connect ‘n’ Grow®, we provide Health education and training to schools, workplaces, and individuals. Our courses are divided into three categories to suit your needs.',
    aboutImage: aboutImage,
    textLogo: 'Connect ‘n‘ Grow',

    logoA: logoA,
    logoB: logoC,
    logoC: logoB,

    title: 'HealthConnectVR',
    defaultAuthor: '1556234485244',

    // Links.
    webUrl: 'https://connectngrow.edu.au',
    about: 'https://connectngrow.edu.au',
    contactUs: 'https://connectngrow.edu.au/contact/',
    privacyPolicy: 'https://connectngrow.edu.au/privacy/',
    emailUrl: 'admin@connectngrow.edu.au',

    oculusQuestLink: '',
    oculusLink: '',
    oculusGoLink: '',
    viveLink: '',
    appStoreLink: 'https://apps.apple.com/us/app/healthconnectvr/id1483475404?ls=1',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.healthvr.mobile',
}

export const firebaseConfig = {
    apiKey: "AIzaSyB4iOGMOCrDkwYgwUxq0fCVTri9BmY3jlg",
    authDomain: "healthvr-6c62b.firebaseapp.com",
    databaseURL: "https://healthvr-6c62b.firebaseio.com",
    projectId: "healthvr-6c62b",
    storageBucket: "gs://farmvr-healthvr",
    messagingSenderId: "407385937959",
    appId: "1:407385937959:web:c6c08a528366c30af35602"
};

// Instructure Data.
let _instructureData = {
    baseUrl: 'https://connectngrow.instructure.com/',
    redirect: 'https://app.connectngrow.edu.au/interactives',
    clientSecret: 'Yg8HGt4hERBNnsKgn8pbnK48zbC141kLq5ddqmTScftystbZAUfF3EaSAF8F4mEY',
    clientId: '115540000000000042',
    loginUrl: '',
    tokenUrl: '',
    getTokenFromCodeData: c => {},
    getTokenFromRefreshData: r => {},
}

_instructureData.loginUrl = `${_instructureData.baseUrl}login/oauth2/auth?client_id=${_instructureData.clientId}&response_type=code&redirect_uri=${_instructureData.redirect}`

_instructureData.tokenUrl = `${_instructureData.baseUrl}login/oauth2/token`
_instructureData.getTokenFromCodeData = c => {
    return {
        grant_type: 'authorization_code',
        client_id: _instructureData.clientId,
        client_secret: _instructureData.clientSecret,
        redirect_uri: _instructureData.redirect,
        code: c
    }
}

_instructureData.getTokenFromRefreshData = r => {
    return {
        grant_type: 'refresh_token',
        client_id: _instructureData.clientId,
        client_secret: _instructureData.clientSecret,
        redirect_uri: _instructureData.redirect,
        refresh_token: r
    }
}


export const instructureData = _instructureData