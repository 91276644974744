import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { getUrl } from './../firebase/Database'

import { getElement } from '../firebase/Database'

import LoadingDot from './LoadingDot'
import { brand } from '../Exports'

const Thumbnail = styled.div`
    background-image: url(${p => p.image});
    background-size: cover;
    background-position: center;
    background-color: ${p => p.theme.darkerLight};
    border-radius: ${p => p.theme.roundCorners};
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    position: relative;
    background-repeat: no-repeat;
`

const Bottom = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2.3em auto;
    grid-column-gap: .5em;
`

const Icon = styled.div`
    background-image: url(${p => p.image});
    background-size: contain;
    background-position: center;
    /* background-color: ${p => p.theme.medium}; */
    border-radius: ${p => p.theme.roundCorners};
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    background-repeat: no-repeat;
`

const Title = styled.div`
    color: ${p => p.theme.medium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Author = styled.div`
    color: ${p => p.theme.gray};
`

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: auto 2.3em;
    grid-gap: .5em;
    cursor: pointer;
    :hover {
        ${Thumbnail} {
            border: .1em solid ${p => p.theme.primary};
            box-shadow: ${p => p.theme.shadow};
        }
        ${Title} {
            color: ${p => p.theme.primary};
        }
    }
`

const AspectRatioBox = styled.div`
    overflow: hidden;
    padding-top: 80%;
    position: relative;
`

const SmallText = styled.div`
    color: ${p => p.theme.light};
    position: absolute;
    bottom: .5rem;
    ${p => p.right && css`
        right: .5rem;
    `}
    ${p => !p.right && css`
        left: .5rem;
    `}
    text-shadow: ${p => p.theme.shadow};
`

export default function ContentThumbnail(props) {
    const [thumbnail, setThumbnail] = useState('')
    const [author, setAuthor] = useState()
    const [authorUrl, setAuthorUrl] = useState('')
    const [image, setImage] = useState()
    const [isLoading, setIsLoading] = useState(true)

    if (!image && thumbnail) {
        let i = new Image()
        i.onload = () => setIsLoading(false)
        i.src = thumbnail
        setImage(i)
    }

    const authorId = props.author ? props.author : brand.defaultAuthor
    if (thumbnail == '') getUrl(Object.values(props.thumbnailImage)[0]).then(setThumbnail)
    if (!author) getElement('authors', authorId).then(a => {
        if (!a) return;
        setAuthor(a)
        getUrl(Object.values(a.logo)[0]).then(setAuthorUrl)
    })

    return (
        <AspectRatioBox>
        <Link to={props.link}>
            <Container>
                <Thumbnail image={thumbnail}>
                    {isLoading && <LoadingDot paddingTop='4.5rem'/>}
                    {props.views && <SmallText>{props.views} views</SmallText>}
                    {props.duration && <SmallText right>{props.duration}</SmallText>}
                </Thumbnail>
                <Bottom>
                    <Icon image={authorUrl}/>
                    <Title>{props.title}</Title>
                    <Author>{author && author.name}</Author>
                </Bottom>
            </Container>
        </Link>
        </AspectRatioBox>
    )
}