import React from 'react'
import styled from 'styled-components'

import TextButton from './../components/TextButton'
import Logo from './Logo'

import { brand } from '../Exports'

const Container = styled.div`
    padding: 2rem;
    padding-top: 1rem;
    background-color: ${p => p.theme.medium};
    height: 17rem;
    display: grid;
    grid-template-columns: auto repeat(3, 20rem) auto;

    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        height: min-content;
        grid-row-gap: 1rem;
    }
`

const MenuPanel = styled.div`
    grid-column: 2/3;
    padding: 1rem;
    display: grid;
    grid-gap: 1rem; 

    * {
        color: ${p => p.theme.light};
    }

    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-row: 1/2;
        grid-column: 1/2;
        border-bottom: .05rem solid ${p => p.theme.light};
    }
`

const UsPanel = styled.div`
    grid-column: 3/4;
    padding: 1rem;
    border-left: .05rem solid ${p => p.theme.light};
    border-right: .05rem solid ${p => p.theme.light};

    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-row: 1/2;
        grid-column: 2/3;
        border-bottom: .05rem solid ${p => p.theme.light};
        border-right: none;
    }
`

const DescriptionPanel = styled.div`
    padding: 0 1rem;
    grid-column: 4/5;
    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-column: 1/3;
        grid-row: 2/3;
    }
    display: grid;
    grid-gap: .1rem;
`

const Description = styled.div`
    color: ${p => p.main ? p.theme.light : p.theme.gray};
    font-size: ${p => p.main ? '.9rem' : '.7rem'};
    line-height: 1.5rem;
`

const Title = styled.div`
    color: ${p => p.theme.gray};
    font-weight: bold;
`

const FlowGrid = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
    margin-bottom: auto;
`

const BigWhite = styled.div`
    color: white;
    font-size: 2rem;
    font-weight: bolder;
`

const LogoHolder = styled.div`
    height: 2rem;
    margin: .2rem 0;
`

export default function Footer(props) {
    const openWeb = (url = brand.webUrl) => window.open(url)
    return (
        <Container>
            <MenuPanel>
                <FlowGrid>
                    <Title>Menu</Title>
                    {props.children}
                </FlowGrid>
            </MenuPanel>
            <UsPanel>
            <FlowGrid>
                <Title>Us</Title>
                <TextButton onClick={() => openWeb(brand.about)} light>About</TextButton>
                <TextButton onClick={() => openWeb(brand.privacyPolicy)} light>Privacy Policy</TextButton>
                <TextButton onClick={() => openWeb(brand.contactUs)} light>Contact Us</TextButton>
                <TextButton onClick={() => openWeb()} light>Visit our Website</TextButton>
            </FlowGrid>
            </UsPanel>
            <DescriptionPanel>
                <LogoHolder><Logo left logo={2}/></LogoHolder>
                <Description main>{brand.getTheAppShortDescription}</Description>
                <Description>{brand.manifesto}</Description>
            </DescriptionPanel>
        </Container>
    )
}
