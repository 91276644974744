import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';

import Logo from './../../components/Logo'

import Button from './../../components/Button'
import { brand } from '../../Exports' 

const Container = styled.div`
    padding-top: 1em;
    display: grid;
    grid-gap: 1.5em;
`

const LogoContainer = styled.div`
    height: 4.5em;
`

const ShortDescription = styled.div`
    font-weight: bold;
    color: ${p => p.theme.medium};
    text-align: center;
`

const Description = styled.div`
    color: ${p => p.theme.gray};
    text-align: center;
`

const Photo = styled.div`
    background-color: ${p => p.theme.medium};
    border-radius: ${p => p.theme.rounderCorners};
    height: 18em;
    background-image: url(${brand.aboutImage});
    background-size: cover;
    background-position: center;
`

const Bottom = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    height: 2em;
    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-auto-flow: row;
    }
    max-width: 40em;
    margin: auto;
`

export default function About(props) {
    window.scrollTo(0,0);
    return (
        <Reveal effect='fadeInUp' duration={500}>
        <Container>
            <LogoContainer><Logo/></LogoContainer>
            <ShortDescription>{brand.getTheAppShortDescription}</ShortDescription>
            <Description>{brand.manifesto}</Description>
            <Photo />
            <Bottom>
                <Button primary onClick={() => window.open(`mailto:${brand.emailUrl}`)}>Get in Touch</Button>
                <Button primary onClick={() => window.open(brand.webUrl)}>Visit our Website</Button>
            </Bottom>
        </Container>
        </Reveal>
    )
}