import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';

import { brand } from '../../Exports'

import Logo from '../../components/Logo'

import appStoreIcon from '../../graphics/getTheAppIcons/appStore.svg'
import googlePlayIcon from '../../graphics/getTheAppIcons/googlePlay.svg'
import oculusIcon from '../../graphics/getTheAppIcons/oculus.svg'
import oculusGoIcon from '../../graphics/getTheAppIcons/oculusGo.svg'
import oculusQuestIcon from '../../graphics/getTheAppIcons/oculusQuest.svg'
import viveIcon from '../../graphics/getTheAppIcons/vive.svg'

const Container = styled.div`
    padding-top: 2rem;
    display: grid;
    grid-gap: 1rem;
`

const LogoContainer = styled.div`
    height: 5rem;
`

const Title = styled.div`
    text-align: center;
    color: ${p => p.theme.medium};
    font-weight: bold;
    font-size: 1.8rem;
`

const SubTitle = styled.div`
    text-align: ${p => p.center ? 'center' : 'start'};
    color: ${p => p.theme.medium};
    font-weight: bold;
    font-size: 1.3rem;
`

const Description = styled.div`
    color: ${p => p.theme.gray};
`

const Line = styled.div`
    height: .05rem;
    background-color: ${p => p.theme.gray};
`

const AspectRatioBox = styled.div`
    padding-top: 50%;
    position: relative;
`

const Block = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${p => p.dark ? p.theme.dark : p.theme.primary};
    border-radius: ${p => p.theme.roundCorners};
    transition: .3s;
    cursor: pointer;
    :hover {
        box-shadow: ${p => p.theme.shadow};
        transform: translateY(-.3rem);
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    @media only screen and (max-width: ${p => p.theme.mobile}) {
        grid-template-columns: 1fr;
    }
`

const Icon = styled.div`
    background-image: url(${p => p.icon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`

export default function About() {
    window.scrollTo(0,0);

    const clickHandler = link => window.open(link)
    const showVR = brand.oculusQuestLink || brand.oculusLink || brand.oculusGoLink || brand.viveLink;

    return (
        <Reveal effect='fadeInUp' duration={500}>
        <Container>
            <LogoContainer><Logo /></LogoContainer>
            <Title>GET THE APPS</Title>
            <Line />
            {showVR && <SubTitle>{`${brand.title} Player`}</SubTitle> }
            {showVR && <Description>{brand.getTheAppFirstDescription}</Description>}
            {showVR && <Grid>
                {brand.oculusQuestLink && <AspectRatioBox><Block dark onClick={() => clickHandler(brand.oculusQuestLink)}><Icon icon={oculusQuestIcon} /></Block></AspectRatioBox>}
                {brand.oculusLink && <AspectRatioBox><Block dark onClick={() => clickHandler(brand.oculusLink)}><Icon icon={oculusIcon} /></Block></AspectRatioBox>}
                {brand.oculusGoLink && <AspectRatioBox><Block dark onClick={() => clickHandler(brand.oculusGoLink)}><Icon icon={oculusGoIcon} /></Block></AspectRatioBox>}
                {brand.viveLink && <AspectRatioBox><Block dark onClick={() => clickHandler(brand.viveLink)}><Icon icon={viveIcon} /></Block></AspectRatioBox>}
            </Grid> }
            {showVR && <Line /> }
            <SubTitle>{`${brand.title} Mobile`}</SubTitle>
            <Description>{brand.getTheAppSecondDescription}</Description>
            <Grid>
                {brand.appStoreLink && <AspectRatioBox><Block dark onClick={() => clickHandler(brand.appStoreLink)}><Icon icon={appStoreIcon} /></Block></AspectRatioBox>}
                {brand.googlePlayLink && <AspectRatioBox><Block dark onClick={() => clickHandler(brand.googlePlayLink)}><Icon icon={googlePlayIcon} /></Block></AspectRatioBox>}
            </Grid>
        </Container>
        </Reveal>
    )
}