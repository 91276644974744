import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';
import { useHistory } from 'react-router-dom'

import { logout } from '../firebase/Auth'

import { useIsLogged, useContent } from './../hooks/CustomHooks'
import { setValue, getUrl } from '../firebase/Database'

import Button from '../components/Button'

import ConfirmAction from '../context/ConfirmActionContext'
import Notifications from '../context/NotificationsContext'

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 5rem;
`

const Title = styled.div`
    color: ${p => p.theme.dark};
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: ${p => p.marginBottom};
`

const Text = styled.div`
    color: ${p => p.theme.dark};
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
`

const SmallText = styled.div`
    color: ${p => p.theme.gray};
`

const Line = styled.div`
    height: .1rem;
    background-color: ${p => p.theme.darkerLight};
    margin: 2rem 0;
`

const Left = styled.div`
`

const ProfilePic = styled.div`
    width: 5rem;
    height: 5rem;
    background-color: ${p => p.theme.medium};
    border-radius: 50%;
    margin-top: 2rem;
`

const Row = styled.div`
    display: grid;
    grid-auto-flow: column;
    height: 2rem;
    width: min-content;
    grid-gap: .5rem;
    margin-bottom: 1rem;
` 

const Banner = styled.div`
    background-image: url(${p => p.url});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
`

function AccessBanner(props) {
    const [url, setUrl] = useState()

    if (!url) {
        if (props.banner && props.banner.thumbnail) getUrl(Object.values(props.banner.thumbnail)[0]).then(setUrl)
        else setUrl('https://firebasestorage.googleapis.com/v0/b/farmvr-74d6a.appspot.com/o/flamelink%2Fmedia%2F1553600107352_TD_Logo_Default.png?alt=media')
    } 

    return (
        <Banner url={url}/>
    )
}

export default function Account() {
    const userState = useIsLogged()
    const history = useHistory()
    const confirmAction = useContext(ConfirmAction)
    const notifications = useContext(Notifications)
    const [codes, loadingCodes] = useContent('accessCode')

    const logoutHandler = () => {
        logout(c => {})
    }

    const editProfileHandler = () => {
        history.push('/profile')
    }

    const redeemAccessCodeHandler = () => {
        if (!userState.loggedUser) return

        confirmAction.ask({
            title: 'Enter your access code',
            confirmText: 'Redeem',
            placeholder: 'code',
            onTextConfirm: code => {
                if (loadingCodes === 'done') {
                    const matchCodes = Object.values(codes).filter(c => c.code === code)
                    if (matchCodes.length != 0 && userState.loggedUser.id) {
                        let key = matchCodes[0].id 
                        if (!userState.loggedUser.accessCodes) userState.loggedUser.accessCodes = {}
                        if (key in userState.loggedUser.accessCodes) {
                            notifications.notify('You already have access')
                            return
                        }
                        userState.loggedUser.accessCodes[key] = key
                        setValue('users', userState.loggedUser.id, { accessCodes : userState.loggedUser.accessCodes })
                        notifications.notify('Code redeemed successfully')
                    }

                    else notifications.notify('Invalid Code')
                }
            }
        })
    }

    const getBanners = () => {
        if (!userState.loggedUser || !userState.loggedUser.accessCodes || loadingCodes != 'done') return <></>
        return Object.values(userState.loggedUser.accessCodes).map(key => <AccessBanner key={key} banner={codes[key]}/>)
    }

    if (!userState.isLoading && !userState.isUserLogged) history.push('')

    return (
        <Reveal effect='fadeInUp' duration={500}>
        <Container>
            <Left>
                <Title>{userState.loggedUser ? userState.loggedUser.userName : 'Username'}</Title>
                <Text>{userState.loggedUser ? `${userState.loggedUser.firstName} ${userState.loggedUser.lastName}`
                    : 'First Last Name'}</Text>
                <SmallText>Location</SmallText>
                <Text>{userState.loggedUser ? userState.loggedUser.country : 'Actual location'}</Text>
                <SmallText>Email</SmallText>
                <Text>{userState.loggedUser ? userState.loggedUser.email : 'Actual email'}</Text>
                <Button onClick={editProfileHandler} primary>Edit My Profile</Button>
                <Line />
                <Row>{getBanners()}</Row>
                <Button onClick={redeemAccessCodeHandler}>Redeem Access Code</Button>
                <Line />
                <Title marginBottom='1.5rem'>Account</Title>
                <Button onClick={logoutHandler} primary>Logout</Button>
            </Left>
        </Container>
        </Reveal>
    )
}