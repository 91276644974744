import React, { useState } from 'react'

const ConfirmAction = React.createContext('')

export function ConfirmActionContext(props) {
    const [state, setState] = useState({
        ask: data => {
            state.title = data.title
            state.confirmText = data.confirmText
            state.cancelText = data.cancelText
            state.onConfirm = data.onConfirm
            state.onCancel = data.onCancel
            state.onTextConfirm = data.onTextConfirm
            state.placeholder = data.placeholder
            state.isActive = true
            state.save()
        },
        title: '',
        confirmText: null,
        cancelText: null,
        onConfirm : null, 
        onCancel: null,
        onTextConfirm : null,
        hide: () => {
            if (state.onCancel) state.onCancel()
            state.isActive = false
            state.text = ''
            state.save()
        },
        isActive: false,
        text: '',
        setText: v => {state.text = v; state.save();},
        save: () => setState({...state})
    })

    return <ConfirmAction.Provider value={state}>{props.children}</ConfirmAction.Provider>
}

export default ConfirmAction